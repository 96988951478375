import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {imageUrlFor} from '../lib/image-url'
import {buildImageObj} from '../lib/helpers'

export default ({node}) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }
  return (
    <figure style={{margin: '0 auto', height: '100%', maxWidth: '1000px'}}>
      <Zoom zoomMargin={150}>
        <img
          src={imageUrlFor(buildImageObj(node))
            .width(1000)
            .auto('format')
            .url()}
            width="675"
          alt={node.alt}
        />
      </Zoom>
      <figcaption>{node.caption}</figcaption>
    </figure>
  )
}
