import React from 'react'
import Figure from './Figure'
import Gist from 'super-react-gist';


const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    gist: ({node}) => {
      const {url} = node;
      return <div style={{maxWidth:"645px"}}><Gist  url={url}></Gist></div>;
    }
  }
}

export default serializers
